<script setup>
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from 'vue-toast-notification';
import ArgonButton from "@/components/Core/ArgonButton.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import EditRatesModal from "./EditRateModal.vue";
import DeleteConfirmationModal from "@/components/Modal/index.vue";
import Tooltip from "@/components/Tooltip/index.vue";
import router from "@/router";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const toast = useToast();
const loading = computed(() => store.getters["agentData/isLoading"]);
const error = computed(() => store.getters["agentData/getError"]);
const userList = computed(() => store.getters["user/getUsers"]);
const userRole = computed(() => store.getters["user/currentUser"].role);
const sortedData = computed(() => {
  if (!sortColumn.value) return filteredJobs.value;

  return [...filteredJobs.value].sort((a, b) => {
    let aVal = a[sortColumn.value];
    
    let bVal = b[sortColumn.value];

    if (sortColumn.value === 'uploadDate') {
      aVal = new Date(aVal);
      bVal = new Date(bVal);
    }

    if (aVal < bVal) return sortDirection.value === 'asc' ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === 'asc' ? 1 : -1;
    return 0;
  });
});

const selectedData = computed(() => 
  Array.isArray(userList.value) ? userList.value.find((item) => item.accountId === editId.value) : {}
)

const agentSearch = ref("");

const sortColumn = ref('');
const editId = ref(-1);
const sortDirection = ref('asc');
const currentPage = ref(1);
const itemsPerPage = ref(10);
const showDeleteModal = ref(false)
const showModal = ref(false)

const itemsPerPageOptions = [5, 10, 25, 50];

const sort = (column) => {
  
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortColumn.value = column;
    sortDirection.value = 'asc';
  }
};

const totalPages = computed(() => Math.ceil(sortedData.value.length / itemsPerPage.value));

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  
  return sortedData.value.slice(start, end);
});

const filteredJobs = computed(() => {
  return Array.isArray(userList.value) ? userList.value.filter(item => {
    const matchesSearch = !agentSearch.value || item.userName.toLowerCase().includes(agentSearch.value.toLowerCase());
    return matchesSearch ;
  }) : [];
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const resetPage = () => {
  currentPage.value = 1;
};

const visiblePageNumbers = computed(() => {
  const totalPageCount = totalPages.value;
  const current = currentPage.value;
  const maxVisiblePages = 3; // Adjust this number to show more or fewer page numbers
  
  let startPage, endPage;
  if (totalPageCount <= maxVisiblePages) {
    // If total pages are less than max visible, show all pages
    startPage = 1;
    endPage = totalPageCount;
  } else {
    // Calculate start and end pages
    const middlePage = Math.floor(maxVisiblePages / 2);
    if (current <= middlePage) {
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (current > totalPageCount - middlePage) {
      startPage = totalPageCount - maxVisiblePages + 1;
      endPage = totalPageCount;
    } else {
      startPage = current - middlePage;
      endPage = current + middlePage;
    }
  }
  
  // Generate page numbers
  let range = [];
  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }
  
  // Add ellipsis if necessary
  if (startPage > 1) range.unshift('...');
  if (endPage < totalPageCount) range.push('...');
  
  // Always show first and last page
  if (startPage > 1) range.unshift(1);
  if (endPage < totalPageCount) range.push(totalPageCount);
  
  return range;
});

const goToPage = (page) => {
  currentPage.value = page;
};

const goToFirstPage = () => {
  currentPage.value = 1;
};

const goToLastPage = () => {
  currentPage.value = totalPages.value;
};

onBeforeMount(() => {
  if (userRole.value !== "ADMIN") return router.back();
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(() => {
  store.dispatch("user/fetchUsers")
});

const handleAgentSearch = () => {
  // store.dispatch("agentData/searchAgents", {
  //   userId: userId.value,
  //   query: agentSearch.value
  // ,});
};

const handleSave = async (rates) => {
  // Handle saving the rates
  try {
    const result = await store.dispatch('user/updateRate', {
      accountId: editId.value,
      smsRate: rates.smsRate,
      imgMmsRate: rates.imgMmsRate,
      vidMmsRate: rates.vidMmsRate
    });

    await store.dispatch("user/fetchUsers")
    if (result.success) {
      // Show success message
      toast.success(result.message);
      // Close modal or perform other UI updates
    } else {
      // Show error message
      toast.error(result.message);
    }
  } catch (error) {
    toast.error('Failed to update rates');
  } finally {
    showModal.value = false
  }
}

const handleCancel = () => {
  showModal.value = false
}

const onAdd = () => {
  store.commit("jobData/toggleAddAgentPanel");
};

const onEdit = async () => {
  // Handle saving the rates
  try {
    const result = await store.dispatch('user/updateStatus', {
      accountId: editId.value
    });

    await store.dispatch("user/fetchUsers")
    if (result.success) {
      // Show success message
      toast.success(result.message);
      // Close modal or perform other UI updates
    } else {
      // Show error message
      toast.error(result.message);
    }
  } catch (error) {
    toast.error('Failed to update rates');
  } finally {
    showDeleteModal.value = false
  }
};

const onClickEdit = async (type, id) => {
  if (type == "delete")
    showModal.value = true;
  else
    showDeleteModal.value = true
  editId.value = id
}

</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-4">Agencies</h2>
        <div class="card min-vh-50">
          <div class="card-body">
            <div class="d-flex align-items-center mb-4">
              <search-bar v-model="agentSearch" @input="handleAgentSearch" />
              <!-- <argon-button color="primary" class="border-radius-2xl ms-2" @click="exportReport">
                Export Report
              </argon-button> -->
              <!-- <argon-button
                v-if="selectedAgentLists.length > 0"
                color="warning"
                size="md"
                class="border-radius-2xl ms-2"
                @click="onEdit()"
              >
                Delete {{ selectedAgentLists.length }} Agent Lists
              </argon-button> -->
              <argon-button
                v-if="userRole !== 'AGENT-READER' && userRole !== 'AGENT-CLICKER'" 
                color="success"
                size="sm"
                class="action-btn rounded-circle ms-auto px-2"
                @click="onAdd()"
              >
                <i class="fas fa-plus text-xs text-white" aria-hidden="true"></i>
              </argon-button>
            </div>
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center"
                    >
                      No
                    </th>
                    <th
                      @click="sort('userName')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Agency Name
                      <span v-if="sortColumn === 'userName'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('agentNumber')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Agency Number
                      <span v-if="sortColumn === 'agentNumber'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('smsRate')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      SMS RATE
                      <span v-if="sortColumn === 'smsRate'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('imgMmsRate')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Image MMS RATE
                      <span v-if="sortColumn === 'imgMmsRate'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('vidMmsRate')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Video MMS RATE
                      <span v-if="sortColumn === 'vidMmsRate'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('status')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Status
                      <span v-if="sortColumn === 'status'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="(agent, index) in paginatedData" 
                    :key="index"
                    :class="index % 2 === 0 ? 'bg-white' : 'bg-light'"
                  >
                    <td class="px-2 text-center">{{ index + 1 }}</td>
                    <td class="px-2 text-center">{{ agent.userName }}</td>
                    <td class="px-2 text-center">{{ agent.agentNumber }}</td>
                    <td class="px-2 text-center">${{ agent.smsRate }}</td>
                    <td class="px-2 text-center">${{ agent.imgMmsRate }}</td>
                    <td class="px-2 text-center">${{ agent.vidMmsRate }}</td>
                    <td class="px-2 text-center">{{ agent.status ? "Enabled" : "Disabled" }}</td>
                    <td class="px-2 text-center">
                      <Tooltip text="Edit Rates">
                        <i
                          v-if="userRole==='ADMIN'"
                          class="fas fa-edit text-primary text-sm opacity-10 px-2" @click.prevent="onClickEdit('delete', agent.accountId)"></i>
                      </Tooltip>
                      <Tooltip text="Edit Status">
                        <i
                          v-if="userRole==='ADMIN'"
                          class="text-primary text-sm opacity-10 px-2" :class="agent.status ? 'fas fa-lock' : 'fas fa-unlock'" @click.prevent="onClickEdit('status', agent.accountId)"></i>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
              <DeleteConfirmationModal 
                :isOpen="showDeleteModal"
                content='Do you want to update the status of this agency?'
                itemName="Status"
                @confirm="onEdit"
                @cancel="showDeleteModal = false"
              />

              <EditRatesModal
                :is-open="showModal"
                :sms-rate="selectedData?.smsRate || 0.0129"
                :img-mms-rate="selectedData?.imgMmsRate || 0.0229"
                :vid-mms-rate="selectedData?.vidMmsRate || 0.0229"
                @confirm="handleSave"
                @cancel="handleCancel"
              />
              <div v-if="totalPages > 1" class="pagination-controls d-flex justify-content-between align-items-center mt-3 px-5">
                <div>
                  <span class="me-2">Rows per page:</span>
                  <select v-model="itemsPerPage" @change="resetPage">
                    <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
                <div class="pagination-nav">
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="goToFirstPage"
                    :disabled="currentPage === 1"
                    title="First Page"
                  >
                    &lt;&lt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    title="Previous Page"
                  >
                    &lt;
                  </button>
                  <template v-for="pageNum in visiblePageNumbers" :key="pageNum">
                    <button
                      v-if="pageNum !== '...'"
                      class="btn btn-sm me-1"
                      :class="pageNum === currentPage ? 'btn-primary' : 'btn-secondary'"
                      @click="goToPage(pageNum)"
                    >
                      {{ pageNum }}
                    </button>
                    <span v-else class="mx-1">...</span>
                  </template>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    title="Next Page"
                  >
                    &gt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="goToLastPage"
                    :disabled="currentPage === totalPages"
                    title="Last Page"
                  >
                    &gt;&gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;  /* Light gray for even rows */
}

.table tbody tr:nth-child(odd) {
  background-color: #ffffff;  /* White for odd rows */
}
</style>