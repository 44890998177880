<script setup>
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import DeleteConfirmationModal from "@/components/Modal/index.vue";
import { getFormattedDate } from "@/utils";
import Tooltip from "@/components/Tooltip/index.vue";

const body = document.getElementsByTagName("body")[0];

const router = useRouter();
const store = useStore();
const identities = computed(() => store.getters["identityData/getIdentities"].filter(itm => itm.identityId !== 1).sort((a, b) => {
      const aVal = new Date(a.startDate);
      const bVal = new Date(b.startDate);
      if (aVal < bVal) return 1;
      else return -1;
    }));
const loading = computed(() => store.getters["identityData/isLoading"]);
const error = computed(() => store.getters["identityData/getError"]);
const userId = computed(() => store.getters["user/currentUser"].id);
const userRole = computed(() => store.getters["user/currentUser"].role);
const sortedData = computed(() => {
  if (!sortColumn.value) return identities.value;

  return [...identities.value].sort((a, b) => {
    let aVal = a[sortColumn.value];
    
    let bVal = b[sortColumn.value];

    if (sortColumn.value === 'startDate') {
      aVal = new Date(aVal);
      bVal = new Date(bVal);
    }

    if (aVal < bVal) return sortDirection.value === 'asc' ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === 'asc' ? 1 : -1;
    return 0;
  });
});

const identitySearch = ref("");
const selectedIdLists = ref([]);
const sortColumn = ref('');
const sortDirection = ref('asc');
const currentPage = ref(1);
const itemsPerPage = ref(10);
const showDeleteModal = ref(false)

const deleteItem = (id) => {
  showDeleteModal.value = true
  selectedIdLists.value = [id]
}
const itemsPerPageOptions = [5, 10, 25, 50];

const sort = (column) => {
  
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortColumn.value = column;
    sortDirection.value = 'asc';
  }
};

const totalPages = computed(() => Math.ceil(sortedData.value.length / itemsPerPage.value));

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  
  return sortedData.value.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const resetPage = () => {
  currentPage.value = 1;
};

const visiblePageNumbers = computed(() => {
  const totalPageCount = totalPages.value;
  const current = currentPage.value;
  const maxVisiblePages = 3; // Adjust this number to show more or fewer page numbers
  
  let startPage, endPage;
  if (totalPageCount <= maxVisiblePages) {
    // If total pages are less than max visible, show all pages
    startPage = 1;
    endPage = totalPageCount;
  } else {
    // Calculate start and end pages
    const middlePage = Math.floor(maxVisiblePages / 2);
    if (current <= middlePage) {
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (current > totalPageCount - middlePage) {
      startPage = totalPageCount - maxVisiblePages + 1;
      endPage = totalPageCount;
    } else {
      startPage = current - middlePage;
      endPage = current + middlePage;
    }
  }
  
  // Generate page numbers
  let range = [];
  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }
  
  // Add ellipsis if necessary
  if (startPage > 1) range.unshift('...');
  if (endPage < totalPageCount) range.push('...');
  
  // Always show first and last page
  if (startPage > 1) range.unshift(1);
  if (endPage < totalPageCount) range.push(totalPageCount);
  
  return range;
});

const goToPage = (page) => {
  currentPage.value = page;
};

const goToFirstPage = () => {
  currentPage.value = 1;
};

const goToLastPage = () => {
  currentPage.value = totalPages.value;
};

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(() => {
  store.dispatch("identityData/fetchIdentities", { userId: userId.value });
  store.dispatch("user/fetchUsers")
});

const handleIdentitySearch = () => {
  store.dispatch("identityData/searchIdentities", {
    userId: userId.value,
    query: identitySearch.value,
  });
};

const onAdd = () => {
  store.commit("identityData/toggleAddIdentityPanel");
};

const onIdentityClick = (identity) => {
  router.push(`${router.currentRoute.value.path}/${identity.identityId}`);
};

const onDelete = async () => {
  try {
    await store.dispatch("identityData/deleteIdentity", selectedIdLists.value)
    console.log('Identities deleted successfully')
    // Clear the selection after successful deletion
    selectedIdLists.value = []
    // You might want to refresh your Identity list here or emit an event to the parent component
    showDeleteModal.value = false
  } catch (error) {
    console.error('Error deleting Identities:', error)
  }
  await store.dispatch("identityData/fetchIdentities", { userId: userId.value });
  
};

</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-4">Manage Identities</h2>
        <div class="card min-vh-50">
          <div class="card-body">
            <div class="d-flex align-items-center mb-4">
              <search-bar v-model="identitySearch" @input="handleIdentitySearch" />
              <argon-button
                v-if="userRole !== 'AGENT-READER' && userRole !== 'AGENT-CLICKER'" 
                color="success"
                size="sm"
                class="action-btn rounded-circle ms-auto px-2"
                @click="onAdd"
              >
                <i class="fas fa-plus text-xs text-white" aria-hidden="true"></i>
              </argon-button>
            </div>
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center justify-content-center mb-0">
                <thead>
                  <tr>
                    <th
                      @click="sort('identityName')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Name
                      <span v-if="sortColumn === 'identityName'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('accountId')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Main Account
                      <span v-if="sortColumn === 'accountId'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <th
                      @click="sort('startDate')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Added On
                      <span v-if="sortColumn === 'startDate'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th>
                    <!-- <th
                      @click="sort('displayName')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      10 DLC Brand
                      <span v-if="sortColumn === 'displayName'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th> -->
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="(identity, index) in paginatedData" 
                    :key="index"
                    :class="index % 2 === 0 ? 'bg-white' : 'bg-light'"
                  >
                    <td class="px-4 text-center">
                      {{ identity.identityName }}
                    </td>
                    <td class="px-4 text-center">{{ identity.userName }}</td>
                    <td class="px-4 text-center">
                      {{ getFormattedDate(identity.startDate) }}
                    </td>
                    <!-- <td class="px-4 text-center">0</td> -->
                    <td class="px-4 text-center">
                      <Tooltip text="Detail identity">
                        <i
                        class="fas fa-eye text-primary text-sm opacity-10 px-2" @click.prevent="onIdentityClick(identity)"></i>
                      </Tooltip>
                      <Tooltip text="Delete identity">
                        <i
                        v-if="userRole==='ADMIN' || userRole=='AGENT-ADMIN'"
                        class="fas fa-trash-can text-primary text-sm opacity-10 px-2" @click.prevent="deleteItem(identity.identityId)"></i>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
              <DeleteConfirmationModal 
                :isOpen="showDeleteModal"
                content='Do you want to delete this item?'
                itemName="Media"
                @confirm="onDelete"
                @cancel="showDeleteModal = false"
              />
              <div v-if="totalPages > 1" class="pagination-controls d-flex justify-content-between align-items-center mt-3 px-5">
                <div>
                  <span class="me-2">Rows per page:</span>
                  <select v-model="itemsPerPage" @change="resetPage">
                    <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
                <div class="pagination-nav">
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="goToFirstPage"
                    :disabled="currentPage === 1"
                    title="First Page"
                  >
                    &lt;&lt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    title="Previous Page"
                  >
                    &lt;
                  </button>
                  <template v-for="pageNum in visiblePageNumbers" :key="pageNum">
                    <button
                      v-if="pageNum !== '...'"
                      class="btn btn-sm me-1"
                      :class="pageNum === currentPage ? 'btn-primary' : 'btn-secondary'"
                      @click="goToPage(pageNum)"
                    >
                      {{ pageNum }}
                    </button>
                    <span v-else class="mx-1">...</span>
                  </template>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    title="Next Page"
                  >
                    &gt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="goToLastPage"
                    :disabled="currentPage === totalPages"
                    title="Last Page"
                  >
                    &gt;&gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;  /* Light gray for even rows */
}

.table tbody tr:nth-child(odd) {
  background-color: #ffffff;  /* White for odd rows */
}
</style>