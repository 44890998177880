<script setup>
import { onBeforeMount, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();
const userRole = computed(() => store.getters["user/currentUser"].role);

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

const onP2PSendClick = () => {
  store.commit("jobData/updateJobType", "P2P");
  store.commit("jobData/updateStatus", "active");
  router.push("/p2p/create");
};

const onHouseFileSendClick = () => {
  store.commit("jobData/updateJobType", "House File");
  store.commit("jobData/updateStatus", "active");
  router.push("/house/create")
};

const onSoapboxP2PSendClick = () => {
  store.commit("jobData/updateJobType", "Soapbox P2P");
  store.commit("jobData/updateStatus", "pending");
  router.push("/soapbox/create")
};

</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-4">Select Send Type</h2>
        <div class="card min-vh-50">
          <div
            class="card-body d-flex flex-lg-row flex-column justify-content-center align-items-center"
          >
            <div
              class="card w-lg-30 w-100 bg-gradient-dark cursor-pointer mx-4 mb-lg-0 mb-4"
              @click="onP2PSendClick()"
            >
              <div class="p-3 card-body d-flex flex-column align-items-center">
                <p class="text-4xl font-weight-bold text-white mb-0">
                  P2P Send
                </p>
              </div>
            </div>
            <div
              v-if="userRole === 'ADMIN' || userRole === 'USER' || userRole === 'AGENT-ADMIN'" 
              class="card w-lg-30 w-100 bg-gradient-dark cursor-pointer mx-4 mb-lg-0 mb-4"
              @click="onHouseFileSendClick"
            >
              <div class="p-3 card-body d-flex flex-column align-items-center">
                <p class="text-4xl font-weight-bold text-white mb-0">
                  House File Send
                </p>
              </div>
            </div>
            <div
              v-if="userRole === 'ADMIN' || userRole === 'USER' || userRole === 'AGENT-ADMIN'" 
              class="card w-lg-30 w-100 bg-gradient-dark cursor-pointer mx-4"
              @click="onSoapboxP2PSendClick"
            >
              <div class="p-3 card-body d-flex flex-column align-items-center">
                <p class="text-4xl font-weight-bold text-white mb-0">
                  Soapbox P2P Send
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
