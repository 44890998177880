<script setup>
import { ref, watch } from "vue";
import { useStore } from "vuex";
// import ArgonButton from "@/components/Core/ArgonButton.vue";

const store = useStore();

const props = defineProps({
  sendList: Object,
  suppressionList: Object,
  emitValidState: Function,
  addPhoneList: Function,
  identityId: Number,
});

const { emitValidState, identityId } = props;

const formData = ref({ sendList: {...props.sendList}, suppressionList: {...props.suppressionList} });

// Watch for changes in phoneListData and update formData accordingly
watch(
  () => props.sendList,
  (newValue) => {
    formData.value.sendList = { ...newValue, available: newValue.available.filter(item=> (item.identityId == identityId || item.identityId == 1) && item.listType !== "Suppression"), assigned: newValue.assigned };
  },
  { deep: true, immediate: true }
);

watch(
  () => props.suppressionList,
  (newValue) => {
    formData.value.suppressionList = { ...newValue, available: newValue.available.filter(item=> item.identityId == identityId), assigned: newValue.available.filter(item=> item.identityId == identityId) };
  },
  { deep: true, immediate: true }
);

// Watch for changes in formData
watch(
  formData,
  () => {
    emitValidState(true);
  },
  { deep: true }
);

// const onAdd = () => {
//   addPhoneList();
// };

// const onAssign = (phoneList) => {
//   formData.value = {
//     available: formData.value.available?.filter(
//       (item) => item.listId !== phoneList.listId
//     ),
//     assigned: [...formData.value.assigned, phoneList],
//   };
  // store.commit("jobData/updatePhoneListStepData", formData.value);
// };

// const onRemoveDuplicate = () => {
//   //
// };

// const onKeepDuplicate = () => {
//   //
// };

// const onUnassign = (phoneList) => {
//   formData.value = {
//     available: [...formData.value.available, phoneList],
//     assigned: formData.value.assigned?.filter((item) => item.listId !== phoneList.listId),
//   };
//   store.commit("jobData/updatePhoneListStepData", formData.value);
// };

const onPhoneListSelect = (selected, e) => {

  if (e.target.checked) {
    if (!formData.value.sendList.assigned.find((item) => item.listId === selected.listId)) {
      formData.value.sendList.assigned = [...formData.value.sendList.assigned, selected];
    }
  } else {
    formData.value.sendList.assigned = formData.value.sendList.assigned.filter(
      (item) => item.listId !== selected.listId
    );
  }
  store.commit("jobData/updatePhoneListStepData", formData.value.sendList);
  store.commit("jobData/updateSuppressionListStepData", formData.value.suppressionList);
};

// const onSuppressionListSelect = (selected, e) => {
//   if (e.target.checked) {
//     if (!formData.value.suppressionList.assigned.find((item) => item.listId === selected.listId)) {
//       formData.value.suppressionList.assigned = [...formData.value.suppressionList.assigned, selected];
//     }
//   } else {
//     formData.value.suppressionList.assigned = formData.value.suppressionList.assigned.filter(
//       (item) => item.listId !== selected.listId
//     );
//   }
//   store.commit("jobData/updateSuppressionListStepData", formData.value.suppressionList);
// };

const checkAssigned = (data, isSuppression) => {
  const sendLists = isSuppression ? formData.value.suppressionList.assigned.filter((item) => item.listId == data.listId) : formData.value.sendList.assigned.filter((item) => item.listId == data.listId)
  return sendLists.length !== 0
}
</script>

<template>
  <div class="row">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h2 class="text-dark">Phone List</h2>
      <!-- <argon-button class="rounded-circle px-3" @click="onAdd">
        +
      </argon-button> -->
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="text-dark">Send Lists</h4>
        <!-- <search-bar /> -->
        <h6 v-if="formData.sendList.assigned.length > 0" class="text-dark">{{formData.sendList.assigned.length}} list selected</h6>
        
      </div>
      <div class="table-responsive p-0 mt-4">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th class=""></th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                State
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Leads Loaded
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.sendList.available" :key="index">
              <td class="px-4">
                <input
                  type="checkbox"
                  id="phoneListSelect"
                  name="phoneListSelect"
                  :checked="checkAssigned(item, false)"
                  @input="(e) => onPhoneListSelect(item, e)"
                />
              </td>
              <td class="px-4">{{ item.listName }}</td>
              <td class="px-4">{{ item.listState }}</td>
              <td class="px-4">{{ item.leadsLoaded || 0 }}</td>
              <!-- <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    color="primary"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onAssign(item)"
                  >
                    <i class="fas fa-user-plus text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-6 col-12">
      <div class="d-flex align-items-center">
        <h4 class="text-dark">Suppression Lists</h4>
      </div>
      <div class="table-responsive p-0 mt-4">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <!-- <th class=""></th> -->
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                State
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Leads Loaded
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formData.suppressionList.available" :key="index">
              <!-- <td class="w-5 px-4">
                <input
                  type="checkbox"
                  id="phoneListSelect"
                  name="phoneListSelect"
                  :checked="checkAssigned(item, true)"
                  @input="(e) => onSuppressionListSelect(item, e)"
                />
              </td> -->
              <td class="px-4">{{ item.listName }}</td>
              <td class="px-4">{{ item.listState }}</td>
              <td class="px-4">{{ item.leadsLoaded || 0 }}</td>
              <!-- <td class="px-4">
                <div class="d-flex">
                  <argon-button
                    v-if="item.deduplicate"
                    color="success"
                    size="sm"
                    class="action-btn rounded-circle ms-auto me-2 px-2"
                    @click="onRemoveDuplicate"
                  >
                    <i class="fas fa-users text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                  <argon-button
                    v-if="!item.deduplicate"
                    color="warning"
                    size="sm"
                    class="action-btn rounded-circle ms-auto me-2 px-2"
                    @click="onKeepDuplicate"
                  >
                    <i class="fas fa-users text-xs text-white" aria-hidden="true"></i>
                  </argon-button>
                  <argon-button
                    color="dark"
                    size="sm"
                    class="action-btn rounded-circle ms-auto px-2"
                    @click="onUnassign(item)"
                  >
                    <i
                      class="fas fa-user-minus text-xs text-white"
                      aria-hidden="true"
                    ></i>
                  </argon-button>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>
.action-btn {
  width: 34px;
  height: 34px;
}
</style>
