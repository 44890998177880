// store/modules/agentData.js
import axios from 'axios';

const state = {
  agents: [],
  editAgentId: '',
  editAgentInfo: {},
  selectedAgent: {},
  loading: false,
  error: null,
};

const mutations = {
  setAgents(state, agents) {
    state.agents = agents;
  },
  setEditAgentId(state, agentId) {
    state.editAgentId = agentId;
  },
  setEditAgentInfo(state, editAgentInfo) {
    state.editAgentInfo = editAgentInfo;
  },
  setSelectedAgent(state, agent) {
    state.selectedAgent = agent;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  async fetchAgents({ commit }, { userId }) {
    commit('setLoading', true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/agents?account_id=${userId}`
      );
      commit('setAgents', response.data);
      commit('setError', null);
    } catch (error) {
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async searchAgents({ commit }, { userId, query }) {
    commit('setLoading', true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/agents?account_id=${userId}&query=${query}`
      );
      commit('setAgents', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Error searching agents:', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async getEditAgentInfo({ commit }, { agentId }) {
    commit('setLoading', true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/agents/${agentId.replace('@', '=')}`
      );

      commit('setEditAgentInfo', response.data);
      commit('setEditAgentId', agentId);
      commit('setError', null);
    } catch (error) {
      console.error('Error searching agents:', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async addAgent({ commit }, payload) {
    commit('setLoading', true);
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/agents`,
        payload
      );
      commit('setSelectedAgent', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Error adding agent', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async updateAgent({ commit }, payload) {
    commit('setLoading', true);
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_API_URL}/agents`,
        payload
      );
      commit('setSelectedAgent', response.data);
      commit('setEditAgentId', '');
      commit('setError', null);
    } catch (error) {
      console.error('Error adding agent', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteAgent({ commit }, payload) {
    commit('setLoading', true);
    try {
      await axios.delete(`${process.env.VUE_APP_API_URL}/agents`, {
        data: { ids: payload },
      });
      commit('setError', null);
    } catch (error) {
      console.error('Error adding DNC', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  getAgents: (state) => state.agents,
  getEditAgentId: (state) => state.editAgentId,
  getEditAgentInfo: (state) => state.editAgentInfo,
  getSelectedAgent: (state) => state.selectedAgent,
  isLoading: (state) => state.loading,
  getError: (state) => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
