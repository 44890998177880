<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";

const router = useRouter();
const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const user = computed(() => store.getters["user/currentUser"]);
const userList = computed(() => store.getters["user/getUsers"]);
const selectedIdentity = computed(() => store.getters["identityData/getSelectedIdentity"]);

const identityData = ref({
  name: ''
});

const accountInfo = ref({});

// Validation state
const errors = ref({
  name: '',
  agency: ''
});

const isValid = computed(() => {
  if (user.value?.role === 'ADMIN') {
    return identityData.value.name && accountInfo.value.accountId;
  }
  return identityData.value.name;
});

// Validation functions
const validateForm = () => {
  errors.value.name = '';
  errors.value.agency = '';
  
  if (!identityData.value.name.trim()) {
    errors.value.name = 'Identity name is required';
  } else if (identityData.value.name.length < 3) {
    errors.value.name = 'Identity name must be at least 3 characters';
  }

  if (user.value?.role === 'ADMIN' && !accountInfo.value.accountId) {
    errors.value.agency = 'Please select an agency';
  }

  return Object.values(errors.value).every(error => !error);
};

const toggleAddIdentityPanel = () => {
  // Reset form state when closing
  errors.value = {
    name: '',
    agency: ''
  };
  identityData.value = { name: '' };
  accountInfo.value = {};
  store.commit("identityData/toggleAddIdentityPanel");
};

const onCreateIdentity = async () => {
  if (!validateForm()) {
    return;
  }

  try {
    const payload = {
      account_id: user.value?.role === "ADMIN" ? accountInfo.value.accountId : user.value?.id,
      identity_name: identityData.value.name,
    };
    await store.dispatch("identityData/addIdentity", payload);
    toggleAddIdentityPanel();
    if (selectedIdentity.value) {
      router.push(`${router.currentRoute.value.path}/${selectedIdentity.value.identityId}`);
    }
  } catch (error) {
    console.error('Failed to create identity:', error);
    errors.value.name = 'Failed to create identity. Please try again.';
  }
};

const onAgencyChange = async (userInfo) => {
  accountInfo.value = userInfo;
  errors.value.agency = ''; // Clear agency error when selection made
};
</script>

<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card overflow-scroll p-4">
      <div class="pb-0">
        <div class="float-start">
          <h5 class="">Identity Manager</h5>
        </div>
        <div
          class="mt-1"
          @click="toggleAddIdentityPanel"
          :class="isRTL ? 'float-start' : 'float-end'"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <div>
        <div class="d-flex my-4">
          <argon-button
            class="border-radius-2xl ms-auto"
            variant="gradient"
            :color="isValid ? 'success' : 'secondary'"
            size="md"
            @click="onCreateIdentity"
            :disabled="!isValid"
          >
            Save
          </argon-button>
        </div>
        <div v-if="user?.role==='ADMIN'">
          <label for="identity-name-input" class="form-control-label">Agency:</label>
          <argon-dropdown class="me-1">
            <template v-slot:title>
              <argon-button color="light" class="dropdown-toggle">
                {{ accountInfo?.userName || "Agency" }}
              </argon-button>
            </template>
            <a
              v-for="(userinfo, index) in userList"
              class="dropdown-item d-flex align-items-center"
              href="#"
              :key="index"
              @click="onAgencyChange(userinfo)"
            >
              <label :for="userinfo.userName" class="mb-0">{{ userinfo.userName }}</label>
            </a>
          </argon-dropdown>
          <div v-if="errors.agency" class="text-danger mt-1 small">
            {{ errors.agency }}
          </div>
        </div>
        <div>
          <label for="identity-name-input" class="form-control-label">Name:</label>
          <argon-input
            id="identityName"
            type="text"
            name="identityName"
            size="md"
            v-model="identityData.name"
            :class="{ 'is-invalid': errors.name }"
          />
          <div v-if="errors.name" class="text-danger mt-1 small">
            {{ errors.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>