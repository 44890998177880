<script setup>
import { onBeforeUnmount, onBeforeMount, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from 'vue-toast-notification';
import { getStates } from "@/utils";

import Navbar from "@/components/PageLayout/Navbar.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonCheckbox from "@/components/Core/ArgonCheckbox.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonRadio from "@/components/Core/ArgonRadio.vue";
import bgImg from "@/assets/img/bg-image.png";
const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();
const type = ref(false);
const jobAreaStates = ref([]);
const stateCode = ref("");
const loading = ref(false);
const $toast = useToast();
const backgroundStyle = computed(() => {
  return {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  }
});
let userName = "";
let email = "";
let password = "";
let agentNumber = "";
let houseNumber = "";
let streetName = "";
let city = "";
let zip = "";

onBeforeMount(() => {
  store.state.app.hideConfigButton = true;
  store.state.app.showNavbar = false;
  store.state.app.showSidenav = false;
  store.state.app.showFooter = false;
  body.classList.remove("bg-gray-100");
  jobAreaStates.value = getStates();
});

onBeforeUnmount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

const onChange = (value) => {
  type.value = value
  console.log('Type changed to:', type.value)
  // You can add any additional logic here
}

const onJobAreaStateSelect = (jobArea) => {
  stateCode.value = jobArea;  
};

const signUp = async () => {
  if(!userName || !email || !password || (type.value && !agentNumber)) {
    return $toast.warning('Please fill in the fields!', { position: "top-right" });
  }
  loading.value = true;

  
  try {
    let signupPromise;
    if (type.value) {
      signupPromise = store.dispatch("user/agentSignup", {
        userName,
        email,
        password,
        agentNumber
      });
    } else {
      signupPromise = store.dispatch("user/signup", {
        userName,
        email,
        password,
        houseNumber,
        streetName,
        stateCode: stateCode.value.code,
        city,
        zip,
      });
    }

    signupPromise
      .then(() => {
        // Successful signup
        console.log("Signup successful");
        // Redirect to home page
        $toast.success('Signup successful!', { position: "top-right" });
        return router.push("/");
      })
      .catch((error) => {
        // Handle specific signup errors
        $toast.error('Signup Failed!', { position: "top-right" });
        console.error("Signup error:", error);
        // You might want to set an error state or display a message to the user here
      });
  } catch (error) {
    // Handle signup error (display error message or log it)
    console.error("Signup error:", error);
  } finally {
    loading.value = false;
  }
  
};
</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      :style="backgroundStyle"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">Welcome!</h1>
            <p class="text-lead text-white">
              Create your account on this amazing texting platform!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Register</h5>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent="signUp">
                <argon-input
                  id="userName"
                  type="text"
                  placeholder="User Name"
                  aria-label="User Name"
                  name="userName"
                  size="lg"
                  v-model="userName"
                />
                <argon-input
                  id="email"
                  type="email"
                  placeholder="Email"
                  aria-label="Email"
                  name="email"
                  size="lg"
                  v-model="email"
                />
                <argon-input
                  id="houseNumber"
                  type="text"
                  placeholder="House Number"
                  aria-label="House Number"
                  name="houseNumber"
                  size="lg"
                  v-model="houseNumber"
                />
                <argon-input
                  id="streetName"
                  type="text"
                  placeholder="Street Name"
                  aria-label="Street Name"
                  name="streetName"
                  size="lg"
                  v-model="streetName"
                />
                <argon-input
                  id="city"
                  type="text"
                  placeholder="City"
                  aria-label="City"
                  name="city"
                  size="lg"
                  v-model="city"
                />
                <argon-dropdown class="mb-3">
                  <template v-slot:title>
                    <argon-button color="light" class="w-100 dropdown-toggle" @click.prevent>
                      {{ stateCode?.name || "State" }}
                    </argon-button>
                  </template>
                  <div class="dropdown-menu-container" style="max-height: 200px; overflow-y: auto;">
                    <a
                      v-for="(jobArea, index) in jobAreaStates"
                      :key="index"
                      class="dropdown-item d-flex align-items-center"
                      @click.prevent="onJobAreaStateSelect(jobArea)"
                    >
                      <label class="mb-0">{{ jobArea?.name }}</label>
                    </a>
                  </div>
                </argon-dropdown>
                <argon-input
                  id="zip"
                  type="text"
                  placeholder="Zip"
                  aria-label="Zip"
                  name="zip"
                  size="lg"
                  v-model="zip"
                />
                <argon-input
                  id="password"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  name="password"
                  size="lg"
                  v-model="password"
                />
                <argon-radio :checked="type === false" @change="onChange(false)">Create a new agency</argon-radio>
                <argon-radio :checked="type === true" @change="onChange(true)">Join an existing agency</argon-radio>
                <argon-input
                  v-if="type === true"
                  id="agentNumber"
                  type="text"
                  placeholder="Agency Number"
                  aria-label="Agency Number"
                  name="agentNumber"
                  size="lg"
                  v-model="agentNumber"
                />
                <argon-checkbox checked>
                  <label class="form-check-label" for="flexCheckDefault">
                    I agree the
                    <a href="javascript:;" class="text-dark font-weight-bolder">
                      Terms and Conditions
                    </a>
                  </label>
                </argon-checkbox>
                <div class="text-center">
                  <argon-button
                    type="submit"
                    fullWidth
                    color="dark"
                    class="my-4 mb-2"
                    :disabled="loading"
                  >
                    <template v-if="!loading">Sign up</template>
                    <template v-else>
                      <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      Loading...
                    </template>
                  </argon-button>
                </div>
                <p class="text-sm mt-3 mb-0">
                  Already have an account?
                  <router-link to="/signin" class="text-dark font-weight-bolder"
                    >Sign in</router-link
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- <app-footer /> -->
</template>
