// store/modules/user.js
import axios from 'axios';

const state = {
  user: null,
  isAuthenticated: false,
  users: [],
  usersList: [],
  messsage: '',
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    state.isAuthenticated = !!user;
  },
  setUsers(state, users) {
    state.usersList = users;
  },
  setMessage(state, message) {
    state.message = message;
  },
  addUser(state, user) {
    state.users.push(user);
  },
};

const actions = {
  async login({ commit }, userData) {
    try {
      // Make the API request to login using Axios
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/users/login`,
        userData
      );

      commit('setMessage', response.data.message);
      // Check if the request was successful
      if (response.status === 200) {
        // Extract user data from the response
        const userData = response.data.data;

        // Commit setUser mutation with user data
        commit('setUser', {
          id: userData.accountId,
          userName: userData.userName,
          agentNumber: userData.agentNumber,
          email: userData.email,
          role: userData.role,
          createdAt: userData.createdAt,
          updatedAt: userData.updatedAt,
        });

        // Return any relevant data from the response
        return { success: true, message: response.data.message };
      } else {
        // If the request was not successful, throw an error
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      // Handle errors, such as network issues or server errors
      if (error?.response?.status === 401)
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      return {
        success: false,
        message: 'Login failed. Please try again later.',
      };
    }
  },
  async agentLogin({ commit }, userData) {
    try {
      // Make the API request to login using Axios
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/agent/accounts/login`,
        userData
      );

      // Check if the request was successful
      if (response.status === 200) {
        // Extract user data from the response
        const userData = response.data.data;

        // Commit setUser mutation with user data
        commit('setUser', {
          id: userData.accountId,
          userName: userData.userName,
          email: userData.email,
          role: userData.role,
          createdAt: userData.createdAt,
          updatedAt: userData.updatedAt,
        });

        // Return any relevant data from the response
        return { success: true, message: response.data.message };
      } else {
        // If the request was not successful, throw an error
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      // Handle errors, such as network issues or server errors
      if (error?.response?.status === 401)
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      else
        return {
          success: false,
          message: 'Login failed. Please try again later.',
        };
    }
  },
  async agentSignup({ commit }, userData) {
    try {
      // Make the API request to login using Axios
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/agent/accounts/signup`,
        userData
      );

      // Check if the request was successful
      if (response.status === 201) {
        commit('addUser', response.data.user);
      } else {
        // If the request was not successful, throw an error
        throw new Error('Signup failed');
      }
    } catch (error) {
      // Handle errors, such as network issues or server errors
      console.error('Signup error:', error);
      return {
        success: false,
        message: 'Signup failed. Please try again later.',
      };
    }
  },
  async signup({ commit }, userData) {
    try {
      // Send a POST request with user data
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/users/signup`,
        userData
      );

      // Check if the request was successful
      if (response.status === 201) {
        // Add new user to the store (replace with database insertion in production)
        commit('addUser', response.data.user);
        return Promise.resolve();
      } else {
        return Promise.reject('Signup failed');
      }
    } catch (error) {
      return Promise.reject(error.response.data.error);
    }
  },
  async fetchUsers({ commit }) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/userList`
      );
      commit('setUsers', response.data);
    } catch (error) {
      console.log('setError', error.message);
      return {
        success: false,
        message: error.message,
      };
    }
  },
  async updateRate(_, payload) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_API_URL}/users/updateRate`,
        payload
      );

      if (response.data.success) {
        // Update store with new data

        return {
          success: true,
          message: 'Rates updated successfully',
        };
      }

      return {
        success: false,
        message: response.data.message || 'Failed to update rates',
      };
    } catch (error) {
      console.error('Error updating rates:', error);
      return {
        success: false,
        message:
          error.response?.data?.message ||
          error.message ||
          'Failed to update rates',
      };
    }
  },
  async updateStatus(_, payload) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_API_URL}/users/updateStatus`,
        payload
      );

      if (response.data.success) {
        // Update store with new data

        return {
          success: true,
          message: 'Rates updated successfully',
        };
      }

      return {
        success: false,
        message: response.data.message || 'Failed to update rates',
      };
    } catch (error) {
      console.error('Error updating rates:', error);
      return {
        success: false,
        message:
          error.response?.data?.message ||
          error.message ||
          'Failed to update rates',
      };
    }
  },
  logout({ commit }) {
    // Perform logout logic, e.g., clear local storage, invalidate tokens, etc.
    // Then, commit setUser mutation with null to clear user data
    commit('setUser', null);
  },
};

const getters = {
  currentUser: (state) => state.user,
  getUsers: (state) => state.usersList,
  getMessage: (state) => state.message,
  isAuthenticated: (state) => state.isAuthenticated,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
